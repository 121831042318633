.outgoing_msg {
	overflow: hidden;
	margin: 26px 0 26px;
}

.sent_msg {
	float: right;
	width: 46%;
	position: relative;
	padding-top: 20px;
}

.sent_msg p {
	background: #3b889c none repeat scroll 0 0;
	border-radius: 3px;
	font-size: 13px !important;
	font-weight: normal !important;
	margin: 0;
	padding: 5px 10px 5px 5px;
	width: 100%;
	color: #fff !important;
}
.incoming_msg_img {
	display: inline-block;
	width: 6%;
}

.received_msg {
	padding-top: 20px;
	width: 54%;
	position: relative;
}

.sms-template {
	background: #f8f8f8;
	padding: 10px 0px;
	border-radius: 10px;
}

.received_withd_msg p {
	background: #ebebeb none repeat scroll 0 0;
	border-radius: 3px;
	color: #2e2e2e;
	font-size: 13px !important;
	font-weight: normal !important;
	margin: 0;
	padding: 5px 10px 5px 12px;
	width: 100%;
}

.time_date {
	color: #747474;
	display: block;
	font-size: 12px;
	margin: 8px 0 0;
}

.received_withd_msg {
	width: 100%;
}

.mesgs {
	float: left;
	padding: 30px 15px 0 25px;
	width: 100%;
}

.menu-link {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  margin: 0;
}

.popup-container {
	text-align: center;
  }
  
  .open-button {
	padding: 10px 20px;
	background-color: #4CAF50;
	color: white;
	border: none;
	cursor: pointer;
  }
  
  .popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  .assign-popup-content {
	background-color: white;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	width: 35%;
  }
  
  .close {
	position: absolute;
	top: 10px;
	right: 15px;
	font-size: 20px;
	cursor: pointer;
  }

  /* .App {
	text-align: center;
	margin-top: 50px;
  } */
  
   .popup-container {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 9999;
  }
  
  .popup-content {
	background-color: #f4f7f8;
	padding: 20px;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
	text-align: left;
	width: 50%;
	height: 95%;
  }
  
  .close-btn {
	cursor: pointer;
	position: absolute;
	top: 10px;
	right: 10px;
  } 

  .orangeTextArea {
	/* background-color: #d2f7ff; */
	padding: 10px;
	border-radius: 5px;
	border: 1px solid #ccc;
	width: 100%; /* Adjust width as needed */
	height: 150px; /* Adjust height as needed */
  }

  .alert {
	padding: 15px;
	margin-bottom: 20px;
	border: 1px solid transparent;
	border-radius: 4px;
  }
  
  .alert p {
	margin: 0;
  }
  
  .close-btn {
	cursor: pointer;
	float: right;
	font-weight: bold;
	font-size: 22px;
	line-height: 20px;
	color: #000;
	text-shadow: 0 1px 0 #fff;
	opacity: 0.2;
  }
  
  .close-btn:hover {
	color: #000;
	text-decoration: none;
	opacity: 0.5;
  }
  
  .success {
	background-color: #dff0d8;
	border-color: #d6e9c6;
	color: #3c763d;
  }
  
  .error {
	background-color: #f2dede;
	border-color: #ebccd1;
	color: #a94442;
  }
  
  .info {
	background-color: #d9edf7;
	border-color: #bce8f1;
	color: #31708f;
  }
  
  .warning {
	background-color: #fcf8e3;
	border-color: #faebcc;
	color: #8a6d3b;
  }

  .react-datepicker-wrapper {
	width: auto !important;
  }
  
  .react-datepicker-wrapper input {
	border-radius: 0px;
	border-top-right-radius: 7px;
	border-bottom-right-radius: 7px;
  }

  .horizontal-list-container {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	overflow-x: auto;
	margin-bottom: 20px;
  }
  
  .horizontal-list {
	display: flex;
	list-style: none;
	padding: 0;
	margin: 0;
  }
  
  .horizontal-list-item {
	margin-right: 10px; /* Adjust spacing between items */
	padding: 5px 10px;
	border: 1px solid #ccc;
	border-radius: 5px;
	background-color: #f0f0f0;
	cursor: pointer;
  }
  
  .horizontal-list-item:last-child {
	margin-right: 0;
  }

  /* Example CSS for table styling */
table {
	width: 100%;
	border-collapse: collapse;
  }
  
  th, td {
	padding: 8px;
	text-align: left;
	border-bottom: 1px solid #dddddd;
  }
  
  th {
	background-color: #f2f2f2;
  }

  .link-button {
	display: inline-block;
	padding: 10px 10px;
	border: none;
	border-radius: 2px;
	background-color: #1498CD;
	color: white;
	text-decoration: none;
	font-size: 13px;
	cursor: pointer;
  }
  
  .link-button:hover {
	background-color: #0e7aa7; /* Darken the color on hover */
  }
  
  

  
  
  
  