/*
* demo.css
* File include item demo only specific css only
******************************************************************************/

.menu .app-brand.demo {
    height: 64px;
  }
  
  .app-brand-logo.demo svg {
    width: 22px;
    height: 38px;
  }
  
  .text-custome {
    color: #696cff;
  }
  .app-brand-text.demo {
    font-size: 1.75rem;
    letter-spacing: -0.5px;
  }
  
  /* ! For .layout-navbar-fixed added fix padding top tpo .layout-page */
  /* Detached navbar */
  .layout-navbar-fixed .layout-wrapper:not(.layout-horizontal):not(.layout-without-menu) .layout-page {
    padding-top: 76px !important;
  }
  /* Default navbar */
  .layout-navbar-fixed .layout-wrapper:not(.layout-without-menu) .layout-page {
    padding-top: 64px !important;
  }
  
  /* Navbar page z-index issue solution */
  .content-wrapper .navbar {
    z-index: auto;
  }
  
  /*
  * Content
  ******************************************************************************/
  
  .demo-blocks > * {
    display: block !important;
  }
  .table > thead tr {
    background: #e7e7ff;
  }
  
  .demo-inline-spacing > * {
    
  }
  
  /* ? .demo-vertical-spacing class is used to have vertical margins between elements. To remove margin-top from the first-child, use .demo-only-element class with .demo-vertical-spacing class. For example, we have used this class in forms-input-groups.html file. */
  .demo-vertical-spacing > * {
    margin-top: 1rem !important;
    margin-bottom: 0 !important;
  }
  .demo-vertical-spacing.demo-only-element > :first-child {
    margin-top: 0 !important;
  }
  
  .demo-vertical-spacing-lg > * {
    margin-top: 1.875rem !important;
    margin-bottom: 0 !important;
  }
  .demo-vertical-spacing-lg.demo-only-element > :first-child {
    margin-top: 0 !important;
  }
  
  .demo-vertical-spacing-xl > * {
    margin-top: 5rem !important;
    margin-bottom: 0 !important;
  }
  .demo-vertical-spacing-xl.demo-only-element > :first-child {
    margin-top: 0 !important;
  }
  
  .rtl-only {
    display: none !important;
    text-align: left !important;
    direction: ltr !important;
  }
  
  [dir='rtl'] .rtl-only {
    display: block !important;
  }
  
  /*
  * Layout demo
  ******************************************************************************/
  
  .layout-demo-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 1rem;
  }
  .layout-demo-placeholder img {
    width: 900px;
  }
  .layout-demo-info {
    text-align: center;
    margin-top: 1rem;
  }
  
  .menu-btn {
    display: none;
  }
  
  @media only screen and (max-width: 600px) {
    .menu-btn {
      position: absolute;
      right: 0px !important;
      right: auto;
      text-align: right;
      top: 14px;
      display: block;
      background: red;
      border: 0px;
      padding: 10px;
      border-top-left-radius: 50%;
      border-bottom-left-radius: 50%;
      color: #fff;
    }
    .layout-page {
      margin-top: 11px;
    }
  }

  .table-container {
    width: 100%;
    overflow-x: auto;
  }
  
  .styled-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    background-color: #f7f7f9;
  }
  
  .styled-table th,
  .styled-table td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .styled-table th {
    background-color: #2c3e50;
    color: #ffffff;
   
  }
  
  .styled-table tbody tr:nth-child(even) {
    background-color: #e5e5e5;
  }

  .table-container {
    max-height: 380px; /* Adjust height as needed */
    overflow-x: auto;
    overflow-y: auto;
}

.styled-table {
    width: 100%; /* Adjust width as needed */
    /* Your other table styles */
}

  
  